import type { LoggerInterface } from 'zeed'
import { Logger } from 'zeed'
import type { AppPluginClass } from '@/_types/plugin'
import { appConfig } from '@/config'

const log: LoggerInterface = Logger('plugins')

const pluginsMap = Object.fromEntries(
  Object.entries(import.meta.glob('./*/index-client.ts')).map(([path, loadLocale]) => [path.match(/\/([\w-]*)\/index-/)?.[1], loadLocale]),
) as Record<string, () => Promise<{ default: AppPluginClass }>>

pluginsMap.tiptap = () => import('briefing-plugin-tiptap')
pluginsMap.tldraw = () => import('briefing-plugin-tldraw')

const pluginsOpenSource: string[] = [
  'media',
  'room',
  'share',
  'stage',
]

const pluginsCommercial: string[] = [
  'briefing',
  'chat-pro',
  'desktop',
  'embed',
  'files',
  'fullscreen',
  'history',
  'i18n',
  'media-pro',
  'push',
  'room-safe',
  'share',
  'sidebar',
  'stage-pro',
  'sync',
  'tldraw',
  'users',
  'settings-pro',
  'toolbar-pro',
]

if (DEBUG) {
  pluginsCommercial.push(...[
    'themes',
    'whiteboard',
    'tiptap',
    'tests',
    'media-background',
    'debug',
  ])
}

const OPENSOURCE = appConfig.getBoolean('OPENSOURCE', false)

const plugins: string[] = OPENSOURCE ? pluginsOpenSource : pluginsCommercial

if (!DEBUG)
  plugins.unshift('sentry')

if (!DEBUG && !OPENSOURCE)
  plugins.unshift('pro')

if (PWA)
  plugins.unshift('pwa')

async function loadPlugin(name: string) {
  log('load', name)
  if (!pluginsMap[name])
    log.warn(`missing plugin ${name}}`)
  return (await pluginsMap[name]()).default
}

export async function getPlugins() {
  log('pluginsMap', pluginsMap)
  log('getPlugins', plugins)
  const pp = await Promise.all(plugins.map(loadPlugin))
  log('pp', pp)
  return pp
}
