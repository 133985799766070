<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { OuiTooltipActivator } from 'oui-float'
import { OuiNotificationActivator } from 'oui-notification'
import { computed } from 'vue'
import AppComponents from '@/components/app-components.vue'

import 'oui-float/css'
import 'oui-notification/css'

import './app.styl'

const headInfo = computed(() => {
  // const lang = locale.value
  return {
    // htmlAttrs: { lang },
    templateParams: {
      site: {
        name: 'Briefing',
      },
      separator: '-',
    },
    titleTemplate: '%s %separator %site.name',
  }
})

useHead(headInfo)
</script>

<template>
  <router-view />
  <OuiNotificationActivator />
  <OuiTooltipActivator />
  <AppComponents placement="app-addition" />
</template>
